<template>
  <div class="ratio ratio-16x9">
    <div :id="playerFrameId">
      <video
        v-if="videoUrl"
        :src="videoUrl"
        ref="video"
        controls
        playsinline
        preload="metadata"
        class="w-100 h-100"
      ></video>
    </div>
  </div>
</template>

<script>
import Player from '@vimeo/player';
import { mapState } from 'vuex';
import Factory from '@/core/factory';
import VideoProvider from '@/core/videoProvider';

const playerVars = {
  autoplay: 0,
  controls: 1,
  enablejsapi: 1,
  fs: 1,
  loop: 0,
  modestbranding: 1,
  playsinline: 1,
  origin: process.env.VUE_APP_WEB_ENDPOINT,
};

export default {
  name: 'VideoPlayer',
  props: {
    video: {
      type: Object,
      required: true,
    },
  },
  watch: {
    YT(newValue, oldValue) {
      if (!oldValue && newValue) {
        this.initYoutubePlayer();
      }
    },
    activeVideoId(newValue) {
      const { videoId, videoType, player } = this;
      if (newValue !== videoId && player) {
        if (videoType === 'youtube') {
          player.pauseVideo();
        } else if (videoType === 'vimeo') {
          player.pause();
        } else if (videoType === 'upload') {
          player.pause();
        }
      }
    },
  },
  computed: {
    ...mapState('video', ['YT', 'activeVideoId']),
    playerFrameId() {
      const id = Factory.createId();
      return `playerframe${id}`;
    },
  },
  methods: {
    initYoutubePlayer() {
      if (this.player || !this.videoId || !this.YT) {
        return;
      }

      this.$nextTick(() => {
        const { videoId, playerFrameId } = this;
        this.player = new this.YT.Player(playerFrameId, {
          videoId,
          playerVars,
          events: {
            onReady: () => {},
            onStateChange: (event) => {
              // -1 (unstarted)
              // 0 (ended)
              // 1 (playing)
              // 2 (paused)
              // 3 (buffering)
              // 5 (video cued)
              const { data } = event;
              if (data === 1 || data === 3) {
                this.$store.dispatch('video/setActiveVideoId', videoId);
              }
            },
          },
        });
      });
    },
    initVimeoPlayer() {
      if (this.player || !this.videoId) {
        return;
      }

      this.$nextTick(() => {
        const { videoId, playerFrameId } = this;
        this.player = new Player(playerFrameId, {
          id: videoId,
          byline: false,
          dnt: true,
          keyboard: false,
          playsinline: true,
          responsive: true,
        });
        this.player.on('play', () => {
          this.$store.dispatch('video/setActiveVideoId', videoId);
        });
      });
    },
    initVideoPlayer() {
      if (this.player || !this.videoId) {
        return;
      }

      this.$nextTick(() => {
        const { videoId } = this;
        this.player = this.$refs.video;
        this.player.addEventListener('play', () => {
          this.$store.dispatch('video/setActiveVideoId', videoId);
        });
      });
    },
  },
  data() {
    return {
      videoUrl: null,
      videoId: null,
      videoType: null,
      player: null,
    };
  },
  mounted() {
    this.videoId = VideoProvider.getYouTubeId(this.video.url);
    if (this.videoId) {
      this.videoType = 'youtube';
      this.initYoutubePlayer();
      return;
    }

    this.videoId = VideoProvider.getVimeoId(this.video.url);
    if (this.videoId) {
      this.videoType = 'vimeo';
      this.initVimeoPlayer();
      return;
    }

    this.videoType = 'upload';
    this.videoId = this.video.url;
    this.videoUrl = this.video.url;
    this.initVideoPlayer();
  },
};
</script>
